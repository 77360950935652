<template>
<div class="nav-container">
    <nav>
        <div class="nav-wrapper" @click="goHome">
            <h1 class="logo"><img class="logo" src="@/assets/unstable-logo.png"> Unstable</h1>
        </div>
        <div v-if="$route.name !== 'Login'" @click="openMenu">
            <font-awesome-icon icon="fa-solid fa-bars" size="xl" />
        </div>
    </nav>
</div>
</template>

<script>
export default {
	name: 'Navigation',
	methods: {
		openMenu() {
			this.$emit('open-menu');
		},
		goHome() {
			this.$router.push({ name: 'Home' });
		}
	}
}
</script>

<style lang="scss" scoped>
.nav-container {
    position:fixed;
    width: 100%;
    height: 4.5rem;
    background-color: #451952;
    display: flex;
    justify-content: center;
    align-items: baseline;
    z-index: 200;

    nav {
        color: #F39F5A;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 0 1rem;
        width: 60rem;

        .logo {
            max-height: 2rem;
            margin-bottom: .35rem;
        }

        .nav-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            .logo, h1 {
                display: inline-block;
                vertical-align: middle;
                cursor: pointer;
            }
        }

        ul {
            list-style: none;
            padding: 0;

            li {
                display: inline-block;
                padding-left: 3rem;
                font-size: 1.3rem;
            }
        }

        a {
            color: #E8BCB9;
            text-decoration: none;
        }
    }
}
</style>
