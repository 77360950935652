import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import router from './router';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown, faPen, faXmark, faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faChevronDown, faPen, faXmark, faBars);

const firebase = require('./firebase.js');

let app;

firebase.auth.onAuthStateChanged(() => {
	if(!app) {
		app = createApp(App)
			.use(router)
			.use(createPinia())
			.component('font-awesome-icon', FontAwesomeIcon)
			.mount('#app');
	}
});
