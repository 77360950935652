<template>
	<div id="menu" class="menu">
		<ul>
			<li><font-awesome-icon @click="closeMenu" icon="fa-solid fa-xmark" size="lg" /></li>
			<li @click="closeMenu"><router-link :to="{ name: 'Home' }">Neste syv dager</router-link></li>
			<li @click="closeMenu"><router-link :to="{ name: 'LastWeek' }">Siste syv dager</router-link></li>
			<li @click="closeMenu"><router-link :to="{ name: 'AllComingDays' }">Alle kommende dager</router-link></li>
			<li @click="closeMenu"><router-link :to="{ name: 'NewDay' }">Legg til dag</router-link></li>
			<li><span @click="logout">Logg ut</span></li>
		</ul>
	</div>
</template>

<script>
import { mapStores } from 'pinia';
import { useUserStore } from '@/stores/userStore';

export default {
	name: 'Menu',
	props: {
		menuOpen: {
			type: Boolean,
			required: true
		}
	},
	watch: {
		menuOpen(newValue) {
			const menu = document.getElementById('menu');

			if (newValue) {
				menu.style.width = window.innerWidth + 'px';
			} else {
				menu.style.width = 0;
			}
		}
	},
	computed: {
		...mapStores(useUserStore)
	},
	methods: {
		closeMenu() {
			this.$emit('close-menu');
		},
		logout() {
			this.closeMenu();
			this.userStore.logout();
		}
	}
}
</script>

<style lang="scss" scoped>
.menu {
	background-color: #451952;
	color: white;
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	height: 100%;
	overflow: hidden;
	z-index: 999;
	transition: width 0.2s ease-out;


	ul {
		list-style: none;
		padding: 0;
		font-size: 1.5rem;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		max-width: 60rem;
		margin: 0.5rem auto;

		li {
			margin: 1rem;

			a {
				text-decoration: none;
				color: #F39F5A;
				cursor: pointer;
			}

			&:not(:first-child) {
				margin-right: 2rem;
			}
		}
	}
}
</style>
