import { getDocs, collection, getDoc, doc, query, where } from 'firebase/firestore';
const firebase = require('@/firebase.js');

const db = firebase.db;
const stablesCollection = collection(db, 'stables');

export default {
	getAllStablesForUser: async (id) => {
		const q = query(stablesCollection, where('stables', 'array-contains', id));
		const docSnap = await getDocs(q);

		return docSnap.map(doc => {
			return {
				...doc.data(),
				id: doc.id
			}
		});
	},
	getStableById: async id => {
		const docSnap = await getDoc(doc(db, 'stables', id));
		return { ...docSnap.data(), id };
	}
};
