import { collection, doc, getDoc, getDocs, query, where, addDoc, updateDoc } from 'firebase/firestore';
import dayjs from 'dayjs';
const firebase = require('@/firebase.js')

const db = firebase.db;
const daysCollection = collection(db, 'days');

export default {
	getAllCalendarDays: async () => {
		const docsSnap = await getDocs(daysCollection);

		return docsSnap.docs?.map(doc => {
			return {
				id: doc.id,
				...doc.data()
			}
		});
	},
	getAllComingCalendarDays: async () => {
		const q = query(daysCollection, where('date', '>=', dayjs().format('YYYYMMDD')));
		const docsSnap = await getDocs(q);

		return docsSnap.docs?.map(doc => {
			return {
				id: doc.id,
				...doc.data()
			}
		});
	},
	getNextWeekOfCalendarDays: async () => {
		const today = dayjs().format('YYYYMMDD');
		const inSevenDays = dayjs().add(7, 'day').format('YYYYMMDD');

		const q = query(daysCollection, where('date', '>=', today), where('date', '<', inSevenDays));
		const docsSnap = await getDocs(q);

		return docsSnap.docs?.map(doc => {
			return {
				id: doc.id,
				...doc.data()
			}
		});
	},
	getLastWeekOfCalendarDays: async () => {
		const today = dayjs().format('YYYYMMDD');
		const sevenDaysago = dayjs().subtract(7, 'day').format('YYYYMMDD');

		const q = query(daysCollection, where('date', '>=', sevenDaysago), where('date', '<', today));
		const docsSnap = await getDocs(q);

		return docsSnap.docs?.map(doc => {
			return {
				id: doc.id,
				...doc.data()
			}
		});
	},
	getSingleCalendarDay: async (id) => {
		const docSnap = await getDoc(doc(db, 'days', id));

		return docSnap.data();
	},
	registerDay: async (day) => {
		return await addDoc(daysCollection, day);
	},
	updateDay: async (id, day) => {
		return await updateDoc(doc(db, 'days', id), day);
	}
}