import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/stores/userStore';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('../views/CalendarPage.vue'),
		props: () => ({
			range: 'nextWeek'
		}),
		meta: {
			authRequired: true
		}
	},
	{
		path: '/last-week',
		name: 'LastWeek',
		component: () => import('../views/CalendarPage.vue'),
		props: () => ({
			range: 'lastWeek'
		}),
		meta: {
			authRequired: true
		}
	},
	{
		path: '/all-coming-days',
		name: 'AllComingDays',
		component: () => import('../views/CalendarPage.vue'),
		props: () => ({
			range: 'all'
		}),
		meta: {
			authRequired: true
		}
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/Login.vue')
	},
	{
		path: '/password-reset',
		name: 'PasswordReset',
		component: () => import('../views/PasswordReset.vue')
	},
	{
		path: '/day/new',
		name: 'NewDay',
		component: () => import('@/views/CalendarForm.vue'),
		meta: {
			authRequired: true
		}
	},
	{
		path: '/day/edit/:id',
		name: 'EditDay',
		component: () => import('@/views/CalendarForm.vue'),
		props: (route) => ({
			id: route.params.id
		}),
		meta: {
			authRequired: true
		}
	}
]

const router = new createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach(async (to, from, next) => {
	const store = useUserStore();
	if (!store.user) {
		await store.fetchUser();
	}

	const isLoggedIn = store.loggedIn;
	const authRequired = to.matched.some((record) => record.meta.authRequired);

	if (authRequired && !isLoggedIn) {
		next('/login');
	} else if (!authRequired && isLoggedIn) {
		next('/');
	} else {
		next();
	}
});

export default router
