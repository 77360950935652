import { getDocs, collection, getDoc, doc } from 'firebase/firestore';
const firebase = require('@/firebase.js');

const db = firebase.db;

export default {
	getAllUsers: async () => {
		const docsSnap = await getDocs(collection(db, 'users'));

		return docsSnap.docs?.map(doc => {
			return {
				id: doc.id,
				...doc.data()
			}
		});
	},
	getUserById: async id => {
		const docSnap = await getDoc(doc(db, 'users', id));
		return { ...docSnap.data(), id };
	}
}
