import { defineStore } from 'pinia';
import { userService } from '@/services';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import router from '@/router';
const firebase = require('@/firebase.js');

export const useUserStore = defineStore('user', {
	state: () => ({
		userData: null,
		loading: false
	}),
	getters: {
		user(state) {
			return state.userData;
		},
		loggedIn(state) {
			return !!state.userData;
		},
		isLoading(state) {
			return state.loading;
		}
	},
	actions: {
		async fetchUser(value) {
			let user = value;

			try {
				if (!user) {
					user = await firebase.auth.currentUser;
				}

				if(user?.uid) {
					const userData = await userService.getUserById(user.uid);
					this.setUser({ ...user, ...userData });
				}
			} catch (e) {
				console.error(e);
			}
		},
		async login(email, password) {
			this.loading = true;

			try {
				const { user } = await signInWithEmailAndPassword(firebase.auth, email, password);
				if (user) {
					this.fetchUser(user);
					router.push({ name: 'Home'});
				} else {
					throw new Error('login failed')
				}
			} catch(e) {
				console.error(e);
			} finally {
				this.loading = false;
			}
		},
		async logout(){
			await signOut(firebase.auth).then(() => {
				this.setUser(null);
				router.push({ name: 'Login' });
			});
		},
		setUser(user) {
			this.userData = user;
		}
	}
});
