import { getDocs, collection, getDoc, doc, query, where } from 'firebase/firestore';
const firebase = require('@/firebase.js');

const db = firebase.db;
const horsesCollection = collection(db, 'horses');

export default {
	getAllHorses: async () => {
		const docSnap = await getDocs(horsesCollection);

		return docSnap.map(doc => {
			return {
				id: doc.id,
				...doc.data()
			}
		});
	},
	getAllHorsesInStable: async (id) => {
		const q = query(horsesCollection, where('stableId', '==', id));
		const docSnap = await getDocs(q);

		return docSnap.map(doc => {
			return {
				id: doc.id,
				...doc.data()
			}
		});
	},
	getAllHorsesOwnedByUser: async (id) => {
		const q = query(horsesCollection, where('ownerId', '==', id))
		const docSnap = await getDocs(q);

		return docSnap.map(doc => {
			return {
				id: doc.id,
				...doc.data()
			}
		});
	},
	getHorseById: async id => {
		const docSnap = await getDoc(doc(db, 'horses', id));
		return { ...docSnap.data(), id };
	}
};
