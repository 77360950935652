<template>
  <div id="app">
    <navigation @open-menu="toggleMenu" />
    <vue-menu @close-menu="toggleMenu" :menu-open="menuOpen" />
    <router-view v-slot="{ Component, route }">
      <component class="container" :is="Component" :key="route.path" />
    </router-view>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import VueMenu from "./components/Menu.vue";

export default {
	name: "App",
	components: {
		Navigation,
		VueMenu,
	},
	data: () => ({
		menuOpen: false
	}),
	methods: {
		toggleMenu() {
			this.menuOpen = !this.menuOpen;
		}
	}
};
</script>

<style lang="scss">
@import "@/style/app.scss";

html,
body {
  color: #1D1A39;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.container {
  padding: 5.5rem 1rem 1rem;
  max-width: 60rem;
  margin: 0 auto;
}
</style>
